<template>
  <a-form>
    <a-row type="flex" justify="space-between">
      <a-col>
        <h3>Knowledge Domains</h3>
      </a-col>
      <a-col>
        <a @click="setKnowledgeDomains([])">none</a>
        <a-divider type="vertical" />
        <a @click="setKnowledgeDomains(cvData.user.knowledge_domains)">users</a>
      </a-col>
    </a-row>

    <a-row v-if="validationError">
      <p class="text-red">{{ validationError }}</p>
    </a-row>

    <div v-if="allKnowledgeDomains.length > 0" class="details-block">
      <draggable v-model="allKnowledgeDomains" handle=".handle" @end="sortKnowledgeDomains">
        <details
          v-for="knowledgeDomain in allKnowledgeDomains"
          :key="knowledgeDomain.id"
          :show-arrow="false"
          class="details"
        >
          <summary class="details-summary">
            <a-checkbox
              :checked="!!find(knowledgeDomain)"
              :value="knowledgeDomain"
              class="ml-2 mr-2"
              @change="handleKnowledgeDomainCheck"
            />
            <h4 class="d-inline-block m-0 mr-2">{{ knowledgeDomain.title }}</h4>
            <small v-if="find(knowledgeDomain)">
              <em class="text-blue mr-1">{{ capitalize(find(knowledgeDomain).level) }}</em>
              <a-icon type="check-circle" theme="twoTone" />
            </small>
            <a-icon type="menu" class="handle" />
          </summary>

          <div class="details-content">
            <h4>Level</h4>
            <a-select
              v-model="knowledgeDomain.level"
              class="mb-3"
              @change="(value) => handleLevelChange(value, knowledgeDomain)"
            >
              <a-select-option value="productive">Productive</a-select-option>
              <a-select-option value="advanced">Advanced</a-select-option>
              <a-select-option value="expert">Expert</a-select-option>
            </a-select>

            <p>{{ knowledgeDomain[`description_${knowledgeDomain.level}`] }}</p>
          </div>
        </details>
      </draggable>
    </div>
  </a-form>
</template>

<script setup lang="ts">
import { emitter } from "@/mitt";
import { ref, onMounted, onUnmounted, toRefs } from "vue";
import draggable from "vuedraggable";
import { KnowledgeDomainInterface } from "../../consultant_profiles/types";
import { CvInterface, CvDataInterface } from "../types";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  cvData: { type: Object as () => CvDataInterface, default: undefined },
  step: { type: Number, default: -1 },
});

// Emits
const emits = defineEmits(["cv-change", "go-to-step"]);

// Data properties
const { cv } = toRefs(props);
const validationError = ref<string | null>(null);
const allKnowledgeDomains = ref<Array<KnowledgeDomainInterface>>([]);
const knowledgeDomains = ref<any>(cv.value.knowledge_domains);

// Life-cycle Hooks
onMounted(() => {
  emitter.on(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as string));

  props.cvData.knowledge_domains.forEach((kd: any) => {
    const selected = knowledgeDomains.value.find(
      (currentKd: any) => currentKd.knowledge_domain_id === kd.id
    );
    const newKD = {
      ...kd,
      level: selected ? selected.level.toLowerCase() : "productive",
    };
    allKnowledgeDomains.value.push(newKD);
  });
});

onUnmounted(() => {
  emitter.off(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as string));
});

// Class Methods
const validate = (nextStep: string) => {
  if (knowledgeDomains.value.length < 1) {
    validationError.value = "At least one knowledge domain is required.";
  } else if (knowledgeDomains.value.length > 3) {
    validationError.value = "You can have at most three knowledge domains.";
  } else {
    validationError.value = null;
    emits("cv-change", "knowledge_domains", knowledgeDomains.value);
    emits("go-to-step", nextStep);
  }
};

const find = (knowledgeDomain: any) => {
  return knowledgeDomains.value.find((kd: any) => kd.knowledge_domain_id === knowledgeDomain.id);
};

const setKnowledgeDomains = (newKnowledgeDomains: KnowledgeDomainInterface[]) => {
  knowledgeDomains.value = newKnowledgeDomains.map((userKd: any) => ({
    knowledge_domain_id: userKd.knowledge_domain.id,
    level: userKd.level,
  }));
};

const capitalize = (txt: string) => {
  return txt.charAt(0).toUpperCase() + txt.slice(1);
};

// @ts-ignore
const handleKnowledgeDomainCheck = ({ target: { value } }) => {
  const i = knowledgeDomains.value.findIndex((kd: any) => kd.knowledge_domain_id === value.id);
  if (i >= 0) knowledgeDomains.value.splice(i, 1);
  else
    knowledgeDomains.value.push({
      knowledge_domain_id: value.id,
      level: value.level,
    });
  sortKnowledgeDomains();
};

const sortKnowledgeDomains = () => {
  knowledgeDomains.value.sort((a: any, b: any) => {
    const aIndex = allKnowledgeDomains.value.findIndex((kd) => kd.id === a.knowledge_domain_id);
    const bIndex = allKnowledgeDomains.value.findIndex((kd) => kd.id === b.knowledge_domain_id);
    return aIndex - bIndex;
  });
  emits("cv-change", "knowledge_domains", knowledgeDomains.value);
};

const handleLevelChange = (level: string, knowledgeDomain: KnowledgeDomainInterface) => {
  const selectedKd = knowledgeDomains.value.find(
    (kd: any) => kd.knowledge_domain_id === knowledgeDomain.id
  );
  if (selectedKd) {
    selectedKd.level = level;
  }
};
</script>

<style lang="scss" scoped>
@use "../../../assets/scss/app";

.details-block {
  border: 1px solid app.$grey_light;
  border-radius: 4px;
  overflow: hidden;
}

.details {
  background: app.$grey_lightest;
  transition: height 1s ease;

  &:not(:last-of-type) {
    border-bottom: 1px solid app.$grey_light;
  }
}

.details-summary {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  outline: none;
}

.details-content {
  padding: 1rem;
  background: white;
  border-top: 1px solid app.$grey_light;
}

.handle {
  margin-left: auto;
  cursor: move;
}
</style>
