<template>
  <a-form>
    <a-row type="flex" justify="space-between">
      <a-col>
        <h3>Skill Categories</h3>
      </a-col>
    </a-row>

    <a-row v-if="validationError">
      <p class="text-red">{{ validationError }}</p>
    </a-row>

    <div v-if="allSkillCategories.length > 0" class="details-block">
      <draggable v-model="allSkillCategories" handle=".handle" @end="sortSkillCategories">
        <details
          v-for="skillCategory in allSkillCategories"
          :key="skillCategory.id"
          :show-arrow="true"
          class="details"
        >
          <summary class="details-summary">
            <a-checkbox
              :checked="!!find(skillCategory)"
              :value="skillCategory"
              class="ml-2 mr-2"
              @change="handleSkillCategoryCheckbox"
            />
            <h4 class="d-inline-block m-0 mr-2">{{ skillCategory.title }}</h4>
            <a-icon type="menu" class="handle" />
          </summary>
        </details>
      </draggable>
    </div>
  </a-form>
</template>

<script setup lang="ts">
import { emitter } from "@/mitt";
import { ref, onMounted, onUnmounted, toRefs } from "vue";
import draggable from "vuedraggable";
import { CvInterface, CvDataInterface, MinimalSkillCategoryCvInterface } from "../types";
import { SkillCategoryInterface } from "@/modules/skills/types";
import { sortByCustomSortOrder } from "../_utils/sorting";

// Props
const props = defineProps({
  cv: { type: Object as () => CvInterface, default: undefined },
  cvData: { type: Object as () => CvDataInterface, default: undefined },
  step: { type: Number, default: -1 },
});

// Emits
const emits = defineEmits(["cv-change", "go-to-step"]);

// Data properties
const { cv } = toRefs(props);
const validationError = ref<string | null>(null);
const allSkillCategories = ref<Array<SkillCategoryInterface>>([]);
const skillCategories = ref<Array<MinimalSkillCategoryCvInterface>>(cv.value.skill_categories);

// Life-cycle Hooks
onMounted(() => {
  emitter.on(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as string));
  allSkillCategories.value = props.cvData?.skill_categories;
  // Pre sort all skill categories so that categories within the cv are in order and first
  allSkillCategories.value = sortByCustomSortOrder<
    SkillCategoryInterface,
    MinimalSkillCategoryCvInterface
  >(
    allSkillCategories.value,
    props.cv?.skill_categories,
    (allSkillCategory) => allSkillCategory.id,
    (cvSkillCategory) => cvSkillCategory.skill_category_id
  );
});

onUnmounted(() => {
  emitter.off(`validate-cv-step-${props.step}`, (nextStep) => validate(nextStep as string));
});

// Class Methods
const validate = (nextStep: string) => {
  emits("cv-change", "skill_categories", skillCategories.value);
  emits("go-to-step", nextStep);
};

const find = (cat: SkillCategoryInterface) => {
  return skillCategories.value.find(
    (cvCat: MinimalSkillCategoryCvInterface) => cvCat.skill_category_id === cat.id
  );
};

const sortSkillCategories = () => {
  skillCategories.value.sort(
    (a: MinimalSkillCategoryCvInterface, b: MinimalSkillCategoryCvInterface) => {
      const aIndex = allSkillCategories.value.findIndex((cat) => cat.id === a.skill_category_id);
      const bIndex = allSkillCategories.value.findIndex((cat) => cat.id === b.skill_category_id);
      return aIndex - bIndex;
    }
  );
  emits("cv-change", "skill_categories", skillCategories.value);
};

const handleSkillCategoryCheckbox = ({
  target: { value },
}: {
  target: { value: SkillCategoryInterface };
}) => {
  const index = skillCategories.value.findIndex(
    (cat: MinimalSkillCategoryCvInterface) => cat.skill_category_id === value.id
  );
  if (index >= 0) {
    skillCategories.value.splice(index, 1);
  } else {
    skillCategories.value.push({ skill_category_id: value.id });
  }
  sortSkillCategories();
};
</script>

<style scoped lang="scss">
@use "../../../assets/scss/app";

.details-block {
  border: 1px solid app.$grey_light;
  border-radius: 4px;
  overflow: hidden;
}

.details {
  background: app.$grey_lightest;
  transition: height 1s ease;

  &:not(:last-of-type) {
    border-bottom: 1px solid app.$grey_light;
  }
}

.details-summary {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  outline: none;
}

.details-content {
  padding: 1rem;
  background: white;
  border-top: 1px solid app.$grey_light;
}

.handle {
  margin-left: auto;
  cursor: move;
}
</style>
