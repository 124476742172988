import Vue, { computed, getCurrentInstance } from "vue";
import { WritableComputedRef } from "vue";
import VueI18n from "vue-i18n";
import { VueConstructor } from "vue/types/umd";
import moment from "moment";
import en from "@/i18n/locales/en.json";
import sv from "@/i18n/locales/sv.json";

export enum LanguageCode {
  English = "en",
  Swedish = "sv",
}

export const getLanguageNameFromCode = (languageCode: LanguageCode): string => {
  const language = Object.entries(LanguageCode).find(([key, value]) => value === languageCode);
  if (!language) {
    throw Error(`Unknown language code '${languageCode}'`);
  }
  return language[0];
};

export interface Language {
  code: LanguageCode;
  name: string;
}

export const englishLanguage: Language = {
  code: LanguageCode.English,
  name: "English",
};

export const swedishLanguage: Language = {
  code: LanguageCode.Swedish,
  name: "Swedish",
};

let i18nInstance: VueI18n | undefined;

export const createI18n = (): VueI18n => {
  i18nInstance = new VueI18n({
    locale: LanguageCode.English,
    messages: {
      en,
      sv,
    },
  });

  return i18nInstance;
};

interface Composer {
  locale: WritableComputedRef<string>;
  t: typeof VueI18n.prototype.t;
  tc: typeof VueI18n.prototype.tc;
  te: typeof VueI18n.prototype.te;
  d: typeof VueI18n.prototype.d;
  n: typeof VueI18n.prototype.n;
}

export const useI18n = (): Composer => {
  if (!i18nInstance) throw new Error("vue-i18n not initialized");

  const i18n = i18nInstance;

  const instance = getCurrentInstance();
  const vm =
    instance?.proxy || (instance as unknown as InstanceType<VueConstructor>) || new Vue({ i18n });

  const locale = computed({
    get() {
      return i18n.locale;
    },
    set(v: string) {
      i18n.locale = v;
    },
  });

  return {
    locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm),
  };
};

export const changeLanguage = (languageCode: LanguageCode) => {
  if (i18nInstance) {
    i18nInstance.locale = languageCode;
    moment.locale(languageCode);
  }
};
