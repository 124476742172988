// Globally available packages
// @ts-ignore
import qs from "qs";
// @ts-ignore
window.qs = qs;
import device from "current-device";
// @ts-ignore
window.device = device;

// External packages
import VeeValidate from "vee-validate";

// Sentry
import * as Sentry from "@sentry/vue";

// @ts-ignore
import VCalendar from "v-calendar";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
import Antd from "ant-design-vue";
import Vue from "vue";

// App business logic modules
import router from "@/router";
import App from "@/App.vue";

import PortalVue from "portal-vue";
Vue.use(PortalVue);

// SCSS
import "ant-design-vue/dist/antd.css";
import "@/assets/scss/app.scss";

// No error outputs in production
Vue.config.productionTip = false;

if (import.meta.env.VITE_ENABLE_DEV_TOOLS) {
  Vue.config.devtools = true;
}

// Bind services to Vue instance
Vue.use(VeeValidate, { events: "" });
Vue.use(VCalendar);
Vue.use(Antd);

Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("VPopover", VPopover);

Sentry.init({
  Vue,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV,
} as Object);

import SkMoney from "@/common/SkMoney.vue";
Vue.component("SkMoney", SkMoney);
import SkInput from "@/common/SkInput.vue";
Vue.component("SkInput", SkInput);
import SkSelect from "@/common/SkSelect.vue";
Vue.component("SkSelect", SkSelect);
import SkNameSelect from "@/common/SkNameSelect.vue";
Vue.component("SkNameSelect", SkNameSelect);
import SkTextarea from "@/common/SkTextarea.vue";
Vue.component("SkTextarea", SkTextarea);
import SkCheckbox from "@/common/SkCheckbox.vue";
Vue.component("SkCheckbox", SkCheckbox);

// Pinia
import { createPinia, PiniaVuePlugin } from "pinia";
const pinia = createPinia();
// Remove PiniaVuePlugin when on Vue 3
Vue.use(PiniaVuePlugin);
// @ts-ignore
Vue.use(pinia);

// Unhead
import { createHead } from "@unhead/vue";
import { UnheadPlugin } from "@unhead/vue/vue2";

const head = createHead();
Vue.use(UnheadPlugin);

// i18n
import VueI18n from "vue-i18n";
import { createI18n } from "@/i18n/index";

Vue.use(VueI18n);
export const i18n = createI18n();

// Create Vue app instance and bind it to the app HTML element
// @ts-ignore
window.$vm = new Vue({
  router,
  i18n,
  unhead: head,
  render: (h) => h(App),
}).$mount("#app");

export { router };
