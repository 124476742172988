export interface JobPostingInterface {
  id: number;
  title: string;
  organisation: string;
  owner: JobPostingUser;
  is_subscribed: boolean;
  has_description: boolean;
  updated_at: string;
  created_at: string;
}

export interface JobPostingUser {
  id: number;
  name: string;
  gravatar: string;
}

export enum JobPostingStatus {
  NEW = "new",
  ONGOING = "ongoing",
}

export interface JobPostingDetailsInterface {
  has_shown_interest: boolean;
  descriptions: JobPostingDescriptionInterface[];
}

export interface JobPostingDescriptionInterface {
  id: number;
  content: string;
  created_by: JobPostingUser;
  created_at: string;
  updated_at: string;
}
