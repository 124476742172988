import moment from "moment";
import { LanguageCode } from "@/i18n";

moment.updateLocale(LanguageCode.English, {
  relativeTime: {
    d: "1 day",
    M: "1 month",
    y: "1 year",
  },
  week: {
    dow: 1,
    doy: 1,
  },
});

moment.defineLocale(LanguageCode.Swedish, {
  months:
    "januari_februari_mars_april_maj_juni_juli_augusti_september_oktober_november_december".split(
      "_"
    ),
  monthsShort: "jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec".split("_"),
  weekdays: "söndag_måndag_tisdag_onsdag_torsdag_fredag_lördag".split("_"),
  weekdaysShort: "sön_mån_tis_ons_tor_fre_lör".split("_"),
  weekdaysMin: "sö_må_ti_on_to_fr_lö".split("_"),
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "YYYY-MM-DD",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY [kl.] HH:mm",
    LLLL: "dddd D MMMM YYYY [kl.] HH:mm",
    lll: "D MMM YYYY HH:mm",
    llll: "ddd D MMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Idag] LT",
    nextDay: "[Imorgon] LT",
    lastDay: "[Igår] LT",
    nextWeek: "[På] dddd LT",
    lastWeek: "[I] dddd[s] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "om %s",
    past: "för %s sedan",
    s: "några sekunder",
    ss: "%d sekunder",
    m: "1 minut",
    mm: "%d minuter",
    h: "1 timme",
    hh: "%d timmar",
    d: "1 dag",
    dd: "%d dagar",
    M: "1 månad",
    MM: "%d månader",
    y: "1 år",
    yy: "%d år",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(\:e|\:a)/,
  ordinal: function (number) {
    var b = number % 10,
      output =
        ~~((number % 100) / 10) === 1
          ? ":e"
          : b === 1
            ? ":a"
            : b === 2
              ? ":a"
              : b === 3
                ? ":e"
                : ":e";
    return number + output;
  },
  week: {
    dow: 1,
    doy: 1,
  },
});

export const RFC_3339: string = "YYYY-MM-DD HH:mm Z";

moment.locale(LanguageCode.English);

export default moment;
