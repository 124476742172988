<template>
  <div>
    <div v-if="filterPanelVisible" class="card mb-3">
      <a-form layout="vertical">
        <a-row>
          <a-col :lg="11" class="ml-3">
            <a-form-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 16 }"
              label="Date"
              class="pb-0 mb-0"
            >
              <a-date-picker
                :value="parseDate(newFilter.start_date)"
                placeholder="Start Date"
                class="w-100 mb-2"
                @change="(date) => onDateChange(date, 'start')"
              />
              <a-date-picker
                :value="parseDate(newFilter.end_date)"
                placeholder="End Date"
                class="w-100 mb-2"
                @change="(date) => onDateChange(date, 'end')"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <portal to="create-invoice-filter-button">
      <a-button
        v-if="isNotEmpty(filters)"
        class="mr-2"
        icon="close"
        type="danger"
        @click="() => clearFilters()"
      />
      <a-button
        v-if="filterPanelVisible"
        icon="up"
        type="primary"
        @click="() => toggleFilterPanel()"
      />
      <a-button
        v-if="!filterPanelVisible"
        icon="filter"
        type="primary"
        @click="() => toggleFilterPanel()"
      />
    </portal>
  </div>
</template>

<script setup lang="ts">
import useMixin from "@/useMixin";
import useFilters from "@/useFilters";
import { ref, computed, onMounted } from "vue";
import moment from "@/date";
import { CreateInvoiceInterface } from "../types";

// Props
const props = defineProps({
  filters: { type: Object as () => CreateInvoiceInterface, default: undefined },
});

// Emits
const emits = defineEmits(["apply-filters"]);

// Mixins
const { isNotEmpty } = useMixin();

// Filters
const { parseDate } = useFilters();

//Data properties
const filterPanelVisible = ref<boolean>(true);

const newFilter = ref<CreateInvoiceInterface>({
  start_date: undefined,
  end_date: undefined,
});

// Lifecycle hooks
onMounted(() => {
  const prevStartOfMonth = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
  const prevEndOfMonth = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
  onDateChange(parseDate(prevStartOfMonth), "start");
  onDateChange(parseDate(prevEndOfMonth), "end");
  applyFilters();
});

// Computed properties
const filterIsEmpty = computed((): boolean => {
  return Boolean(
    newFilter.value.start_date === undefined && newFilter.value.end_date === undefined
  );
});

// Component methods
const toggleFilterPanel = (): void => {
  filterPanelVisible.value = !filterPanelVisible.value;
};

const applyFilters = (): void => {
  emits("apply-filters", filterIsEmpty.value ? {} : newFilter.value);
};

const handleClearReportsFilter = (): void => {
  newFilter.value.start_date = undefined;
  newFilter.value.end_date = undefined;
};

const clearFilters = (): void => {
  handleClearReportsFilter();
  emits("apply-filters", {});
};

const onDateChange = (date: any, type: string): void => {
  if (type === "start") {
    newFilter.value.start_date = date ? date.format("YYYY-MM-DD") : undefined;
  } else {
    newFilter.value.end_date = date ? date.format("YYYY-MM-DD") : undefined;
  }
  applyFilters();
};
</script>
