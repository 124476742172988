<template>
  <div class="card">
    <div class="flex-center-between">
      <h2 class="m-0">Useful Links</h2>

      <div
        v-if="baseAcl.isAdmin"
        class="action-default d-flex align-items-baseline"
        @click="addLinkModalVisible = true"
      >
        <a-icon type="plus" class="pr-1" />
        <span>Add Link</span>
      </div>
    </div>

    <empty-resource-table v-if="!links[0]" resource="links" />

    <div>
      <li v-for="link in links" :key="link.id" class="my-2">
        <div class="useful-link-wrapper">
          <a :href="link.link" target="_blank">{{ link.title }}</a>

          <div v-if="baseAcl.isAdmin" class="d-flex align-items-center">
            <!-- DELETE LINK -->
            <div class="action-danger" @click="showDeleteLinkConfirm(link.id)">
              <a-icon type="delete" class="pr-1" />Delete
            </div>
          </div>
        </div>
      </li>
    </div>

    <!-- ADD LINK MODAL -->
    <add-link-modal
      :visible="addLinkModalVisible"
      @store-link="(newLink) => handleStoreLink(newLink)"
      @close="addLinkModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, getCurrentInstance, computed, watch, onMounted } from "vue";
import EmptyResourceTable from "@/modules/common/_components/EmptyResourceTable.vue";
import { apiGetLinks, apiStoreLink, apiDestroyLink } from "../_utils/api";
import { BaseAclInterface } from "@/modules/authentication/types";
import { useAuthenticationStore } from "@/modules/authentication/_store";
import { LinkInterface, LinkPayloadInterface } from "../types";
import AddLinkModal from "../_components/AddLinkModal.vue";

// Props
const props = defineProps({
  tab: { type: String, default: undefined },
});

// Pinia
const authenticationStore = useAuthenticationStore();
const baseAcl = computed<BaseAclInterface>(() => authenticationStore.baseAcl);

// Instances
const instance = getCurrentInstance();
const $message = instance?.proxy?.$message;
const $confirm = instance?.proxy?.$confirm;

// Data properties
const addLinkModalVisible = ref<boolean>(false);
const links = ref<Array<LinkInterface>>([]);

// Watchers
watch(
  () => props.tab,
  (newProp, oldProp) => {
    if (newProp === "links" && oldProp !== "links") {
      apiGetLinks().then(({ data }: any) => (links.value = data.data));
    }
  }
);

// Lifecycle hooks
onMounted(() => {
  apiGetLinks().then(({ data }: any) => (links.value = data.data));
});

// Class methods
const handleStoreLink = (newLink: LinkPayloadInterface): void => {
  apiStoreLink(newLink)
    .then(({ data }: any) => links.value.push(data.data))
    .then(() => $message?.success("Link added successfully!", 3))
    .catch(() => $message?.error("Couldn't add link!", 3));
};

const handleDestroyLink = (linkId: number) => {
  apiDestroyLink(linkId)
    .then(() => (links.value = links.value.filter((l) => l.id !== linkId)))
    .then(() => $message?.success("Link deleted successfully!", 3))
    .catch(() => $message?.error("Couldn't delete link!", 3));
};

const showDeleteLinkConfirm = (linkId: number) => {
  const mod = $confirm?.({
    title: "Are you sure you want to delete this link?",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      handleDestroyLink(linkId);
      mod?.destroy();
    },
  });
};
</script>

<style lang="scss">
@use "../../../assets/scss/app";

.useful-link-wrapper {
  border: 1px solid app.$grey_light;
  border-radius: 3px;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
