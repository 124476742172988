import { CvInterface } from "../types";
import axios from "@/network";

export function apiGetCvs(params: any) {
  const url = `/cvs`;
  return axios.get(url, { params });
}

export function apiUpdateCv(updatedCv: CvInterface) {
  const url = `/cvs/${updatedCv.id}`;
  const payload = { cv: updatedCv };
  return axios.patch(url, payload);
}

export function apiGetCv(cvId: number, languageCode: string) {
  const url = `/cvs/${cvId}`;
  return axios.get(url, {
    headers: {
      "Accept-Language": languageCode,
    },
  });
}

export function apiGetCvData(cvId: number) {
  const url = `/cvs/${cvId}/data`;
  return axios.get(url);
}
