import { defineStore, acceptHMRUpdate } from "pinia";
import { CreateInvoiceInterface, StateInterface } from "@/modules/invoices/types";
import {
  apiGetProjectInvoices,
  apiCreateProjectInvoice,
  apiDestroyProjectInvoice,
  apiGetInvoices,
} from "@/modules/invoices/_utils/api";

export const useInvoicesStore = defineStore("invoices", {
  state: (): StateInterface => {
    return {
      invoicesMap: new Map(),
      invoicesPagination: undefined,
      invoices: [],
      invoice: undefined,
      reports: [],
    };
  },
  actions: {
    getInvoicesByPage(params: any) {
      return new Promise((resolve, reject) => {
        apiGetInvoices(params)
          .then((res: any) => {
            this.setInvoices(res.data.data);
            this.setInvoicesPagination(res.data.meta);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    getProjectInvoicesByPage(projectId: number, params: any) {
      return new Promise((resolve, reject) => {
        apiGetProjectInvoices(projectId, params)
          .then((res: any) => {
            this.setInvoices(res.data.data);
            this.setInvoicesPagination(res.data.meta);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    destroyInvoice({ projectId, invoiceId }: { projectId: number; invoiceId: number }) {
      return new Promise((resolve, reject) => {
        apiDestroyProjectInvoice(projectId, invoiceId)
          .then((res: any) => {
            this.removeInvoice(invoiceId);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    getReportsForInvoice(projectId: number, params: CreateInvoiceInterface) {
      return new Promise((resolve, reject) => {
        apiCreateProjectInvoice(projectId, params)
          .then((res: any) => {
            this.setInvoiceReports(res.data.data);
            resolve(res.data.data);
          })
          .catch((err: any) => {
            reject(err);
          });
      });
    },

    setInvoices(invoices: Array<any>) {
      this.invoices = invoices;
    },

    setInvoice(invoice: any) {
      this.invoice = invoice;
    },

    setInvoiceReports(reports: Array<any>) {
      this.reports = reports;
    },

    removeInvoice(invoiceId: number) {
      this.invoices = this.invoices.filter((i) => i.id !== invoiceId);
    },

    setInvoicesMap({ page, invoices }: { page: number; invoices: Array<any> }) {
      this.invoicesMap = this.invoicesMap.set(page, invoices);
    },

    setInvoicesPagination(pagination: any) {
      this.invoicesPagination = pagination;
    },
  },
});

// HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useInvoicesStore, import.meta.hot));
}
